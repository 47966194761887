import type { NodeSearchResult } from '@stoplight/elements-dev-portal'
import { Search as ElementsSearch, useGetNodes } from '@stoplight/elements-dev-portal'
import * as React from 'react'
import { withQueryClientProvider } from '@stoplight/elements-core'

export type SearchProps = { projectIds: string[]; basePath: string }

const BaseSearch = ({ projectIds, basePath }: SearchProps) => {
  const [search, setSearch] = React.useState('')
  const [open, setOpen] = React.useState(false)
  const { data } = useGetNodes({
    search,
    projectIds,
  })

  const handleClose = () => {
    setSearch('')
    setOpen(false)
  }

  const handleClick = (data: NodeSearchResult, basePath: string) => {
    const url = new URL(window.location.origin + basePath)
    handleClose()
    window.location.href = url.href + `/${data.slug}`
  }

  return (
    <div className="wrapper">
      <button onFocus={() => setOpen(true)}>
        <span>Search</span>
      </button>

      <ElementsSearch
        search={search}
        onSearch={setSearch}
        onClick={data => handleClick(data, basePath)}
        onClose={handleClose}
        isOpen={open}
        searchResults={data}
      />
    </div>
  )
}

export const Search = withQueryClientProvider(BaseSearch)
