import React, { FC } from 'react'
import { Route } from 'react-router-dom'
import { Search } from '../../components'
import { StoplightProject } from '@stoplight/elements-dev-portal'
import { Helmet } from 'react-helmet'

type ProjectRouteProps = { path: string; projectId: string; theme: string; title: string }

export const ProjectRoute: FC<ProjectRouteProps> = ({ path, projectId, theme, title }) => {
  if (!projectId) {
    console.error(`projectId for project ${path} is: ${projectId}`)

    return <h1>We're sorry. Something has gone wrong</h1>
  }

  return (
    <Route path={path}>
      <Helmet>
        <title>{title} API Documentation</title>
        <link rel="icon" href={`${process.env.PUBLIC_URL}/favicons/${theme}/favicon.ico`} type="image/x-icon" />
      </Helmet>
      <div className={`${theme} elements-container`}>
        <div className="navigation">
          <a href={path}>
            <img
              className="logo"
              src={`${process.env.PUBLIC_URL}/logos/${theme}/logo.png`}
              alt={`The logo for ${theme}`}
            />
          </a>

          <Search projectIds={[projectId]} basePath={path} />
        </div>

        <StoplightProject projectId={projectId} basePath={path} />
      </div>
    </Route>
  )
}
