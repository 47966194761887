import './styles/index.scss'
import { ProjectRoute } from './components'
import { projectKeys } from './config/projectKeys'
import { Switch, HashRouter, Redirect } from 'react-router-dom'

const { smart, niac, heat } = projectKeys

function App() {
  return (
    <div className="App">
      <HashRouter>
        <Switch>
          <ProjectRoute path="/smart" projectId={smart} theme="smart" title="Smart" />
          <ProjectRoute path="/niac" projectId={niac} theme="niac" title="Niac" />
          <ProjectRoute path="/heat" projectId={heat} theme="heat" title="Heat" />
          <Redirect to="/smart" />
        </Switch>
      </HashRouter>
    </div>
  )
}

export default App
